import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../utility/utils"
import UkPolicyContent from "../policy-content/uk-policy-content"
const PrivacyUk = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <div className="kuda-inner--heading mt-0 kuda-disclaimer--section">
        <div className="kuda-section--inner">
        <UkPolicyContent/>
        </div>
      </div>
    </Fragment>
  )
}

export default PrivacyUk
