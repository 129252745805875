import React from "react"
import PrivacyUk from "../../components/body/pages/privacy/privacyUk"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const PrivacyPageUk = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/legal/uk-privacy/"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PrivacyUk />
  </NoheaderFooter>
)

export default PrivacyPageUk
